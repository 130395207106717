<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";

/**
 * Video component
 */
export default {
  page: {
    title: "Video",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "가이드",
      items: [
        {
          text: "UI Elements",
        },
        {
          text: "Video",
          active: true,
        },
      ],

      guideURLList: [
        {videoLink: 'https://www.youtube.com/embed/dxBmWYCQZYk', videoTitle: '쏘마 관리자 페이지 사용방법 (메뉴관리)'},
        {videoLink: 'https://www.youtube.com/embed/ydzoUZJ6OPA', videoTitle: '쏘마 관리자 페이지 사용방법 (이벤트 관리)'},
        {videoLink: 'https://www.youtube.com/embed/s8BEbawvrAM', videoTitle: '쏘마 관리자 페이지 사용방법 (PUSH 관리)'},
      ]
    }
  },

  components: {
    Layout,
    PageHeader,
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div v-for="(row, idx) in guideURLList" :key="idx" class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{ row.videoTitle }}</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                :src="row.videoLink"
                title="YouTube video"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
